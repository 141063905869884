export default {
  colors: {
    text: '#111',
    background: '#FFFBFF',
    green: '#273B09',
    black: '#100B00',
    blue: '#0B3C49',
    primary: '#273B09',
    gray: '#BFC3BA',
  },
  forms: {
    input: {},
    select: {
      borderRadius: 9999,
    },
    textarea: {},
    label: {},
    radio: {},
    checkbox: {
      color: 'black',
    },
  },
  variants: {
    card: {
      p: 2,
      bg: 'background',
      boxShadow: 'card',
      borderRadius: 2,
    },
    formCard: {
      p: 3,
      bg: 'background',
      boxShadow: 'card',
      borderRadius: '5px 5px 0 0',
      paddingTop: 2,
      paddingBottom: 2
    },
    heroCard: {
      p: 2,
      bg: 'background',
      boxShadow: 'card',
      borderRadius: 2,
      opacity: .8
    },
    nav: {
      color: 'black',
      paddingBottom: '2px',
      my: 'auto',
      
      '&:hover': {
        borderBottomColor: 'black',
        borderBottomWidth: '2px',
        borderBottomStyle: 'solid'
      }
    },
    mobileNav: {
      color: 'black',
      paddingBottom: '2px',
      my: 'auto',
      textTransform: 'uppercase',
      fontSize: 5,
      py: 5,
    },
    phoneStripe: {
      color: 'black',
      paddingBottom: '2px',
      my: 'auto',
    },
    footer: {
      color: 'white',
      width: '100%',
      mb: 1
      // '&:hover': {
      // }
    },
    footerHeader: {
      fontSize: 4,
      color: 'white',
      width: '100%',
      mb: 2
    },
    mobileMenuClosed: {
      background: 'white',
      left: 0,
      padding: 3,
      position: 'fixed',
      top: 0,
      transition: 'background 0.05s',
      transitionDelay: '0s',
      width: '100%',
      zIndex: 20,
      opacity: .8,

      '@media screen and (min-width: 64em)': {
        display: 'none'
      }
    },
    mobileMenuOpened: {
      background: 'white',
      left: 0,
      padding: 3,
      position: 'fixed',
      top: 0,
      transition: 'background 0.05s',
      transitionDelay: '0.4s',
      width: '100%',
      zIndex: 20,

      '@media screen and (min-width: 64em)': {
        display: 'none'
      }
    },
    closeButtonBottom: {
      background: 'black',
      bottom: 1,
      borderRadius: '100%',
      boxShadow: '0 3px 3px rgba(0, 0, 0, 0.25)',
      color: 'whie',
      fontSize: 3,
      height: 7,
      left: '40%',
      pb: '4px',
      position: 'fixed',
      transition: 'opacity 0.2s, transform 0.3s ease-in-out 0.4s',
      width: 7,
      zIndex: 30
    }
  },
  breakpoints: ['40em', '52em', '64em'],
  fonts: {
    body: "Lato, system-ui, sans-serif",
    heading: "Lato, system-ui, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [5, 7],
      my: 2
    },
    subheading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [3, 5],
      mb: 4
    },
    large: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: '700',
      fontSize: [3, 4],
    },
    body: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: 3,
    },
    caption: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: 2,
    },
    formTitle: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [3, 4],
      my: 2
    },
    formHeader: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'bold',
      fontSize: 2,
    },
    button: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: 3,
    },
  },
  space: [
    0, 4, 8, 16, 32, 64, 128, 256
  ]
}